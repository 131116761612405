<template>
    <o-data-lookup :data-object="dsLookupDataObject">
        <template #target="{ target }">
            <component v-if="is" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue"></component>
            <input v-else-if="textInput" :ref="target" v-bind="$attrs" :value="textInputValue">
            <span v-else :ref="target" style="cursor:pointer;">                
            </span>
        </template>
        <o-column field="Name" width="200"></o-column>
        <o-column field="NumberOfDays" width="100"></o-column>
    </o-data-lookup>
</template>

<script setup>
    import { computed, ref, watch, watchEffect } from 'vue';
    import { getOrCreateDataObject } from 'o365.vue.ts'

    const props = defineProps({
        is: String,   
        textInputValue: String,
        textInput: Boolean
    });

    const dsLookupDataObject = getOrCreateDataObject({
        id: 'dsLookupDataObject' + crypto.randomUUID(),
        viewName: 'atbv_Check_ChecklistFrequencies',
        maxRecords: 25,
        whereClause: "",
        loadRecents: false,
        distinctRows: true,
        fields: 
             [{name: "Name", type: "string" },             
             {name: "NumberOfDays", type: "Number" }]
    }); 

</script>